import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { ApiUrl } from '../API/ApiUrl';


const ModalForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        products: '',
        message: ''
    });

    useEffect(() => {
        const modalShown = sessionStorage.getItem('modalShown');
        if (!modalShown) {
            const timer = setTimeout(() => {
                const modal = document.getElementById('exampleModalCenter');
                if (modal) {
                    const modalInstance = new window.bootstrap.Modal(modal);
                    modalInstance.show();
                    sessionStorage.setItem('modalShown', 'true');
                }
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, []);
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.name || !formData.email || !formData.mobile || !formData.products || !formData.message) {
            Swal.fire({
                title: 'Oops...!',
                text: 'All Fields are Required.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }
        const mobileRegex = /^\d{10}$/;
        if (!mobileRegex.test(formData.mobile)) {
            Swal.fire({
                title: 'Oops...!',
                text: 'Mobile number should be 10 digits.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            return;
        }
        try {
            const laravelResponse = await axios.post(`${ApiUrl}/cristo/contact`, formData);
            if (laravelResponse.status === 200) {
                const response = await axios.post('https://api.sendinblue.com/v3/smtp/email', {
                    sender: { name: "CristO", email: formData.email },
                    to: [
                        { email: 'imman@boscosofttech.com' },
                        { email: 'sudhakar@boscosofttech.com' }
                    ],
                    cc: [
                        { email: 'cristo@boscosofttech.com' },
                        { email: 'info@boscosofttech.com' },
                        { email: 'sudhakar@boscosofttech.com' }
                    ],
                    subject: 'Request For Demo',
                    htmlContent: `<!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Contact Form Email</title>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">
            <style>
              body {
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                background-color: #f8f9fa;
                padding: 20px;
              }
              .email-container {
                max-width: 600px;
                margin: 0 auto;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                padding: 30px;
              }
              .message-box {
                padding: 20px;
                border-left: 4px solid #007bff;
                font-style: italic;
                background-color: #f0f0f0;
                margin-top: 20px;
                border-radius: 5px;
              }
              .btn {
                background-color: #007bff;
                color: #fff;
                padding: 10px 20px;
                border-radius: 5px;
                text-decoration: none;
                transition: background-color 0.3s ease;
              }
              .btn:hover {
                background-color: #0056b3;
                color:#fff
              }
              h2 {
                color: #007bff;
                text-align: center;
                margin-bottom: 20px;
              }
              p {
                font-size: 16px;
                line-height: 1.6;
              }
              b {
                color: #007bff;
              }
              .footer-text {
                text-align: center;
                margin-top: 20px;
                font-size: 14px;
              }
              .footer-text a {
                color: #007bff;
                text-decoration: none;
              }
              .footer-text a:hover {
                text-decoration: underline;
              }
            </style>
          </head>
          <body>
            <div class="email-container">
              <h2>New Message from ${document.getElementById("name").value}</h2>
              <p>Hello <b>Admin,</b></p>
              <div class="message-box">
                    <p><strong>Name:</strong> ${document.getElementById("name").value}</p>
                    <p><strong>Email:</strong> ${document.getElementById("email").value}</p>
                    <p><strong>Mobile:</strong> <a href="tel:${document.getElementById("mobile").value}">${document.getElementById("mobile").value}</a></p>
                    <p><strong>Product:</strong> ${document.getElementById("products").value}</p>
                    <p><strong>Message:</strong> ${document.getElementById("message").value}</p>
              </div>
              <p>Best wishes,<br><b>CristO team</b></p>
              <hr>
              <p>If you want to visit our Website, click the button below:</p>
              <a href="https://cristoerp.com/" target="_blank" class="btn">Visit Website</a>
              <div class="footer-text">
              <p>Thank you for reaching out! For more information, visit our website <a href="https://cristoerp.com/" target="_blank">here</a>.</p>
              </div>
            </div>
          </body>
          </html>
          `
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'api-key': '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'
                    }
                });
                if (response.status === 201) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Your request has been sent successfully.',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    });
                    setFormData({
                        name: '',
                        mobile: '',
                        email: '',
                        products: '',
                        message: ''
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    throw new Error('Failed to send email.');
                }
            } else {
                throw new Error('Failed to store form data in Laravel API.');
            }
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: error.message || 'Failed to send message. Please try again later.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };
    return (
        <>
            <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
                data-backdrop="static"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: "rgb(249, 249, 249)" }}>
                            <h5 className="modal-title" id="exampleModalLongTitle">
                                <b>Request for demo</b>
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true" style={{ color: "#002856 !important" }}>×</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ backgroundColor: "rgb(249, 249, 249)" }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <form
                                        method="post"
                                        id="myForm"
                                        className="php-email-form"
                                        autoComplete="off"
                                        onSubmit={handleSubmit}
                                        style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", padding: "17px 17px 0px 17px", backgroundColor: "#f9f9f9" }}
                                    >
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="name">
                                                    Name <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="mobile">
                                                    Mobile <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="tel"
                                                    className="form-control"
                                                    id="mobile"
                                                    name="mobile"
                                                    value={formData.mobile}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="email">
                                                    Email <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="col-md-6 form-group">
                                                <label htmlFor="products">
                                                    Product <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="products"
                                                    name="products"
                                                    value={formData.products}
                                                    onChange={handleChange}
                                                >
                                                    <option value="" disabled>Select a product</option>
                                                    <option value="parokia">Parokia</option>
                                                    <option value="religio">ReligiO</option>
                                                    <option value="cathedra">Cathedra</option>
                                                    <option value="charisma">Charisma</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="message">
                                                Message <span style={{ color: "red" }}>*</span>
                                            </label>
                                            <textarea
                                                className="form-control"
                                                name="message"
                                                id="message"
                                                rows={3}
                                                value={formData.message}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-block mt-3"
                                            id="submitBtn"
                                        >
                                            Request for demo
                                        </button>
                                        <br />
                                        <br />
                                    </form>
                                </div>
                                <div className="col-md-6">
                                    <div className="trial-process">
                                        <h5><b>Demo in 3 easy steps:</b></h5>
                                        &#10004; Fill out the form to the left<br />
                                        &#10004; Click the "Request for demo" button below<br />
                                        &#10004; Stay tuned for the next steps<br /><br />

                                        <h5><b>Contact Us:</b></h5>
                                        <p>Bosco Soft Technologies Pvt. Ltd.,<br />
                                            No. 231/77, S.H.C Complex,<br />
                                            Vaniyambadi Road, Tirupattur District,<br />
                                            Tamil Nadu, India - 635 601</p>
                                        <p><a href="mailto:info@boscosofttech.com">info@boscosofttech.com</a></p>
                                        <p><a href="tel:9786000436">+91 97860 00436</a></p>
                                    </div>
                                </div>
                                <small className="text-center mt-3">
                                    By clicking "Request for demo" you agree to our{" "}
                                    <a href="https://boscosofttech.com/terms-of-use" target="_blank" rel="noreferrer">Terms</a> and acknowledge our{" "}
                                    <a href="https://boscosofttech.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalForm;
