import React from 'react';

const AboutUs = () => {
    return (
        <>
            <section id="about" className="section about section-bg">
                <div className="container aos-init aos-animate" data-aos="fade-up">
                    <br />
                    <div className="section-title">
                        <h3>
                            <span>About Bosco Soft</span>
                        </h3>
                        <h2>Innovate and Lead</h2>
                    </div>
                    <div className="row">
                        <div
                            className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={100}
                        >
                            <ul>
                                <li>
                                    <div>
                                        <h3 style={{ fontWeight: 'bold' }}>
                                            What Bosco Soft Stands For
                                        </h3>
                                        <p>
                                            &#10004; Developing complete, feature-rich, and scalable ERP solutions tailored to specific needs and requirements
                                            <br />
                                            <br />
                                            &#10004; Innovative products and excellent support that meet or exceed client expectations
                                            <br />
                                            <br />
                                            &#10004; Timely delivery
                                            <br />
                                            <br />
                                            &#10004; Unassailable support
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <a
                                            href="https://www.boscosofttech.com/about"
                                            target="_blank"
                                            className="link-text w-inline-block"
                                            rel="noreferrer"
                                        >
                                            <div className="text-in-link">Know More</div>
                                            <div className="small-arrowlink txt-blue w-embed">
                                                <svg
                                                    viewBox="0 0 96 96"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <title />
                                                    <path d="M69.8437,43.3876,33.8422,13.3863a6.0035,6.0035,0,0,0-7.6878,9.223l30.47,25.39-30.47,25.39a6.0035,6.0035,0,0,0,7.6878,9.2231L69.8437,52.6106a6.0091,6.0091,0,0,0,0-9.223Z" />
                                                </svg>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={100}
                        >
                            <ul>
                                <li>
                                    <div>
                                        <h3 style={{ fontWeight: 'bold' }}>
                                            IT and IT-Enabled Solution Provider for Church Corporates and Service Sectors
                                        </h3>
                                        <p>
                                            Our strength lies in our innovative approach, user-friendliness, and robust customer support, with a focus on mobile apps, IoT, and AI integration.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h3 style={{ fontWeight: 'bold' }}>Our Mission</h3>
                                        <p>
                                            We strive to achieve optimal results for our stakeholders by employing cutting-edge technologies, involving committed associates, and being driven by innovation and leadership.
                                        </p>
                                        <a
                                            href="https://www.boscosofttech.com/about"
                                            target="_blank"
                                            className="link-text w-inline-block"
                                            rel="noreferrer"
                                        >
                                            <div className="text-in-link">Know More</div>
                                            <div className="small-arrowlink txt-blue w-embed">
                                                <svg
                                                    viewBox="0 0 96 96"
                                                    fill="currentColor"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <title />
                                                    <path d="M69.8437,43.3876,33.8422,13.3863a6.0035,6.0035,0,0,0-7.6878,9.223l30.47,25.39-30.47,25.39a6.0035,6.0035,0,0,0,7.6878,9.2231L69.8437,52.6106a6.0091,6.0091,0,0,0,0-9.223Z" />
                                                </svg>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUs;
