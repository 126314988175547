import React, { useState } from 'react';

const Slider = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handlePrev = () => {
        const index = (activeIndex - 1 + 4) % 4;
        setActiveIndex(index);
    };

    const handleNext = () => {
        const index = (activeIndex + 1) % 4;
        setActiveIndex(index);
    };

    const slides = [
        {
            backgroundImage: "url(assets/img/cristo/04-slide.jpg)",
            title: "<span style='color: #018bd2'>Welcome to</span> <br/> Church Management Software <span style='color:#ff8001'>( CristO )</span>",
            content: "Store & organize the member data that’s best for your church <b>“Church Sans Paper”</b> Effective parish management software is a comprehensive digital solution designed to streamline and enhance the administrative and organizational tasks of a religious congregation. Manage families, members, and sacraments with ease. CristO is accessible at the diocesan, parish, family, or individual member level. A diocesan administrator can access all information related to the diocese.",
        },
        {
            backgroundImage: "url(assets/img/cristo/2-slide.png)",
            title: "Province Management Software <span style='color:#ff8001'>( ReligiO )</span>",
            content: "A paperless technology driver back office for an administration. This user-friendly software tool is designed for the religious head of an institute, province secretary, community heads, and all province members. It allows for the management of communications (circulars, emails, meetings), creation of records (chronicles, concerns), and maintenance of critical and personal member information. The software also supports tracking assignments, history, institutional/community information, planning, reporting, and archiving.",
        },
        {
            backgroundImage: "url(assets/img/cristo/5-slide.jpg)",
            title: "Diocese Management Software <span style='color:#ff8001'>( Cathedra )</span>",
            content: "Diocese Management Software functions as a centralized hub for overseeing diverse operations within a diocese, streamlining administrative tasks, and fostering better communication and collaboration among clergy, staff, and parishioners. By consolidating parishioner information, personnel records, financial data, and event planning tools into one platform, the software enhances efficiency and organization.",
        },
        {
            backgroundImage: "url(assets/img/cristo/6-slide.jpg)",
            title: "Parish Management Software <span style='color:#ff8001'>( Parokia  )</span>",
            content: "A comprehensive church management software that makes managing your church easier. Parishes can manage families, members, and sacraments with ease. CristO is accessible at the diocesan, parish, family, or individual member level. Diocesan administrators have full access to all information related to the diocese.",
        },
    ];

    return (
        <section id="hero">
            <div
                id="heroCarousel"
                data-bs-interval={8000}
                className="carousel slide carousel-fade"
                data-bs-ride="carousel"
            >
                <ol className="carousel-indicators" id="hero-carousel-indicators">
                    {slides.map((slide, index) => (
                        <li
                            key={index}
                            data-bs-target="#heroCarousel"
                            data-bs-slide-to={index}
                            className={index === activeIndex ? "active" : ""}
                        />
                    ))}
                </ol>
                <div className="carousel-inner" role="listbox">
                    {slides.map((slide, index) => (
                        <div
                            key={index}
                            className={`carousel-item ${index === activeIndex ? "active" : ""}`}
                            style={{ backgroundImage: slide.backgroundImage }}
                        >
                            <div className="overlay"></div>
                            <div className="carousel-container">
                                <div className="container">
                                    <h2 className="animate__animated animate__fadeInDown" dangerouslySetInnerHTML={{ __html: slide.title }}></h2>
                                    <p className="animate__animated animate__fadeInUp" dangerouslySetInnerHTML={{ __html: slide.content }}></p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <a className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev" onClick={handlePrev}>
                    <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true" />
                </a>
                <a className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next" onClick={handleNext}>
                    <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true" />
                </a>
            </div>
        </section>
    );
};

export default Slider;
