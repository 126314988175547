import React from 'react'

const WelcomeCristo = () => {
    return (
        <>
            <h2 className="mt-4 text-center" id='welcomecristo'>
                Welcome to the Smartest Way to Energise your
                <br /> <b style={{ color: "#018bd2" }}>Parish, Province and Diocese</b>
            </h2>
            <section className="section-padding" id="section_2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-12">
                            <img
                                src="assets/img/cristo/tour.png"
                                className="custom-text-box-image img-fluid"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-7 col-12">
                            <div className="custom-text-box">
                                <br />
                                <p className="text-black" id="aboutcontent">
                                    Experience firsthand with our guided tour! Pre-loaded with demo data, it provides instant access to explore all features without the hassle of setup. Navigate modules, interact with functionalities, and evaluate how it meets your needs before making a commitment. Ready to begin this insightful journey? Start your tour today!
                                </p>
                                <p
                                    data-toggle="modal"
                                    data-target="#exampleModalCenter"
                                    className="buttonguid w-button"
                                >
                                    Start Guided Tour
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default WelcomeCristo
